<template>
  <loader v-bind="{ loading }">
    <form>
      <columns>
        <column class="is-narrow">
          <switch-input 
            classes="is-medium is-rounded" 
            :value="customer.is_business" 
            @input="toggleIsBusiness">
            Business
          </switch-input>
        </column>
        <column>
          <text-input
            classes="is-medium is-rounded"
            required :value="customer.name"
            :error="$root.errors.name"
            @input="updateName"
            description="The legal name of the business or person">
            <span
            v-if="customer.is_business">Company</span>
            Name
          </text-input>
        </column>
        <column class="is-4">
            <text-input
              classes="is-medium is-rounded"
              :value="customer.reference"
              @input="updateReference"
              description="An internal comnpany reference if applicable">
              Reference <small class="has-text-weight-light">(Optional)</small>
            </text-input>
          </column>
      </columns>
      <columns>
        <column>
          <submit-button
            class="is-medium is-rounded"
            :working="updating"
            :disabled="$root.cant('manage-customers') || deleting"
            @submit="updateCustomer">
            Update Customer
          </submit-button>
          <p class="help is-danger" v-if="$root.cant('manage-customers')">
            Please contact your administrator to adjust your
            permissions.</p>
        </column>
        <column class="is-narrow">
          <action-button 
            @click="deleteCustomer" 
            :working="deleting"
            :disabled="updating"
            class="is-danger is-rounded is-small" 
            left-icon="trash">
            Delete
          </action-button>
        </column>
      </columns>
    </form>
  </loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    loading: true,
    updating: false,
    deleting: false,
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadList')
    await this.$store.dispatch('customer/loadLocationTypes')
    this.loading = false
  },

  methods: {
    ...mapMutations('customer', [
      'updateName',
      'toggleIsBusiness',
      'updateAddress',
      'updateLocationType',
      'updateLocationName',
      'updateLocationBranches',
      'updateReference'
    ]),
    updateCustomer() {
      this.updating = true
      this.$store.dispatch('customer/update').then(() => {
        this.$toast.success('Saved')
        this.updating = false
      }).catch(() => this.updating = false)
    },
    async deleteCustomer() {
      if(await this.$confirm({
        title: 'Delete Customer',
        message: 'Are you sure you want to delete this customer. All associated data for this customer will also be removed'
      })) {
        this.deleting = true
        this.$store.dispatch('customer/deleteCustomer').then(() => {
          this.$toast.success('Success')
          this.$router.push({
            name: 'customers'
          })
          this.deleting = false
        }).catch(() => this.deleting = false)
      }
    },
  },

  computed: {
    ...mapGetters('branch', [
      'branch_list'
    ]),
    ...mapGetters('customer', [
      'customer',
      'location_types'
    ])
  }

}
</script>